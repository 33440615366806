import React, { useState, useEffect, useContext } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { getValueFromObject } from '@helpers/utils'
import NextError from 'next/error'
import { fetchData } from '@helpers/contentful'
import { STATIC_PAGES } from '@consts/index'
import Head from '@components/Head'
import NotFound from './404'
import { NextPageContext } from 'next'
import { EmptyObject } from 'types/helpers'
import { PageProps } from '@components/pages/dynamic'
import AppContext from '@components/AppContext'
import { HeaderTheme } from 'types/header'

const Pages = dynamic(() => import('@components/pages'))

type MetaImage = {
  type: string
  file: {
    url: string
    contentType: string
  }
}

type PageEntry = {
  pageTitle: string
} & {
  seo_description: string
  headerTheme?: HeaderTheme
} & PageProps

function getPageTitle(
  query: {
    contentfulId?: string
    pagesHandle?: string
  },
  page: PageEntry[]
) {
  if (query.contentfulId) {
    return (page.find(obj => !!obj.pageTitle) || {}).pageTitle
  }

  return (
    STATIC_PAGES.find(staticPage => staticPage.handle === query.pagesHandle) ||
    {}
  ).title
}

type PageNextProps = {
  pageEntries: PageEntry[]
  handle: string
  title: string
  description: string
  contentfulId: string
  statusCode: number
  metaImage: {
    url: string
    type: string
    width: number | string
    height: number | string
  }
}

const PageNext = ({
  pageEntries,
  handle,
  title,
  description,
  statusCode,
  contentfulId,
  metaImage
}: PageNextProps) => {
  const { setHeaderTheme } = useContext(AppContext)
  const [previewPageComponents, setPreviewPageComponents] = useState<
    PageEntry[] | null
  >(null)
  const { query } = useRouter()

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = (await fetchData({
          id: contentfulId,
          isPreview: true
        })) as PageEntry[]
        setPreviewPageComponents(response)

        const pageHeaderTheme = response?.find(
          entry => entry?.headerTheme !== undefined
        )?.headerTheme as HeaderTheme

        if (pageHeaderTheme) {
          setHeaderTheme(pageHeaderTheme)
        }
      } catch (e) {
        console.error(e, { contentfulId })
      }
    }
    if (contentfulId && query.preview) {
      fetchPage()
    }
  }, [contentfulId, query.preview, setHeaderTheme])

  if (statusCode === 404) {
    return <NotFound />
  }

  if (statusCode !== 200) {
    return <NextError statusCode={statusCode} />
  }

  return (
    <>
      <Head title={title} desc={description} image={metaImage} />
      <Pages
        handle={handle}
        page={previewPageComponents || pageEntries || []}
      />
    </>
  )
}

PageNext.getInitialProps = async ({ query }: NextPageContext) => {
  try {
    const contentfulId = Array.isArray(query.contentfulId)
      ? query.contentfulId[0]
      : query.contentfulId
    const pagesHandle = Array.isArray(query.pagesHandle)
      ? query.pagesHandle[0]
      : query.pagesHandle

    let pageEntries: PageEntry[] = []

    if (contentfulId) {
      pageEntries = (await fetchData({ id: contentfulId })) as PageEntry[]
    }

    const pageExists =
      !!query.contentfulId ||
      STATIC_PAGES.findIndex(staticPage => staticPage.handle === pagesHandle) >
        -1

    // fetch the page Title either from contentful, either from static_page in consts
    const title = getPageTitle(query, pageEntries)
    const href =
      pagesHandle === '/' ? '' : `/pages/${pagesHandle?.replace(/^\//, '')}`
    const description = (pageEntries.find(obj => !!obj.seo_description) || {})
      .seo_description
    const metaImageEntry = pageEntries.find(obj => obj.type === 'metaImage')
    const metaImage = getValueFromObject<MetaImage['file'] | EmptyObject>(
      metaImageEntry,
      'file',
      {}
    )
    return {
      pageEntries,
      contentfulId: query.contentfulId,
      href,
      title,
      description,
      metaImage: {
        url: metaImage.url,
        type: metaImage.contentType,
        width: getValueFromObject(metaImage, 'details.image.width', null),
        height: getValueFromObject(metaImage, 'details.image.height', null)
      },
      handle: query.pagesHandle,
      statusCode: pageExists ? 200 : 404
    }
  } catch (e) {
    return {
      statusCode: 500
    }
  }
}

export default PageNext
